import {buildPrefetchUrls} from '@@client/lib/util'

export function getClientConfig() {
    return {
        dnsPrefetchUrls: buildPrefetchUrls([
            process.env.AUTH_API_URL,
            process.env.CONTENT_API_URL,
            process.env.INVENTORY_API_URL,
            process.env.STORE_API_URL,
            process.env.MEDIA_API_URL,
            process.env.MESSAGE_API_URL,
            process.env.ORDER_API_URL,
        ]),
        tracking: {
            gaTrackingId: process.env.GA_TRACKING_ID,
            facebookPixelId: process.env.FACEBOOK_PIXEL_ID,
        },
        auth: {
            apiUrl: process.env.AUTH_API_URL,
            claimsNamespace: process.env.JWT_CLAIMS_NAMESPACE,
            portalId: process.env.PORTAL_ID,
        },
        inventory: {
            apiUrl: process.env.INVENTORY_API_URL,
            // these part types do not have warranties
            noWarrantyPartTypes: [
                253,
                950,
            ],
        },
        location: {
            apiUrl: process.env.STORE_API_URL,
        },
        chat: {
            apiUrl: '', // TODO
        },
        contact: {
            phoneFull: '+18553391932',
            phoneHuman: '1-855-339-1932',
            carBuyers: {
                phoneFull: '+18668900316',
                phoneHuman: '1-866-890-0316',
            },
        },
        media: {
            apiUrl: process.env.MEDIA_API_URL,
            engineInspectionVideoUrl: 'https://www.youtube.com/embed/nB-PipGxi8s',
            transmissionInspectionVideoUrl: 'https://www.youtube.com/embed/yJdoXMWUJDE',
        },
        message: {
            apiUrl: process.env.MESSAGE_API_URL,
            newsletterSignupEnabled: true,
        },
        orders: {
            apiUrl: process.env.ORDER_API_URL,
            claimsNamespace: process.env.ORDER_JWT_CLAIMS_NAMESPACE,
        },
        affirm: {
            orderMinimumDollars: Number(process.env.AFFIRM_MINIMUM_ORDER_DOLLARS || 150),
        },
        hiring: {
            enabled: Boolean(process.env.ENABLE_HIRING_FORM),
        },
    }
}

export function getServerConfig() {
    return {
        ...getClientConfig(),
        content: {
            apiUrl: process.env.CONTENT_API_URL,
        },
    }
}
